import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { EditModProps, EditModState } from "../../../interfaces/menu";
import { connect } from "react-redux";
import jwt from "jsonwebtoken";
import { secretKey } from "../../../secret";
import {
  logoutUser,
  editModifier,
  getModifier,
  modGroupsList,
} from "../../../redux";
import Topbar from "../../../components/topbar";
import Sidebar from "../../../components/sidebar";
import Footer from "../../../components/footer/main";
import CheckChanges from "../../../components/confirmOnLeave";
import Select from "react-select";
import { instashop_categories } from "../instahop_categories";

class EditMod extends Component<EditModProps, EditModState> {
  constructor(props: any) {
    super(props);
    this.state = {
      modGroupJson: [],
      modifiername: "",
      disabled: false,
      name_ar:"",
      erpid: "",
      poscode: "",
      priority: "",
      brand_id: null,
      brand_name: "",
      modifiertype: "",
      modifiercost: "",
      modifiersale: "",
      images: null,
      instashop_remote_code: "",
      instashop_category: "",
      erp_id: "",
    };
    this.handleInputChange = this.handleInputChange.bind(this);
  }
  componentDidMount() {
    let id = this.props.match.params.id;
    let type = this.props.match.params.type;
    this.props.getModifier(id, type);
    // this.props.modGroupsList();
    document.title = "SimpleXSync | Modifiers";
  }
  UNSAFE_componentWillReceiveProps(nextProps: any) {
    console.log(nextProps.modData,'nextProps.modDatas')
    this.setState({
      modGroupJson:
        nextProps.modData.mod_groups_json &&
        JSON.parse(nextProps.modData.mod_groups_json),
      priority: nextProps.modData.priority,
      brand_id: nextProps.modData.brand_id,
      brand_name: nextProps.modData.brand_name,
      erp_id: nextProps.modData.erp_id,
      modifiername: nextProps.modData.modifier_name,
      name_ar: nextProps.modData.name_ar,
      modifiertype: nextProps.modData.modifier_type,
      modifiercost: nextProps.modData.modifier_cost_price,
      modifiersale: nextProps.modData.modifier_sale_price,
      instashop_remote_code: nextProps.modData.instashop_remote_code,
      instashop_category: nextProps.modData.instashop_category,
    });
  }
  handleInputChange(event: { target: { name: any; value: any } }) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }
  fileSelectedHandler = (e: any) => {
    if (e.target.files.length > 0) {
      this.setState({ images: e.target.files });
    }
  };
  isModifierReady = () => {
    const { modifiername, modifiercost, modGroupJson, modifiersale, disabled } =
      this.state;
    return (
      modifiername !== "" &&
      modGroupJson &&
      modGroupJson.length > 0 &&
      modifiercost !== "" &&
      modifiersale !== "" &&
      disabled != true
    );
  };
  handleModGroupsInputChange = (e: any, index: any) => {
    if (e && e.length > 0) {
      this.setState({ modGroupJson: e });
    }
  };
  handleState = (value: any) => {
    this.setState({ disabled: value });
  };
  handleSubmit = (event: any) => {
    this.setState({ disabled: true });
    let {
      modifiername,
      brand_id,
      erpid,
      poscode,
      priority,
      instashop_category,
      erp_id,
      modifiertype,
      modifiercost,
      modGroupJson,
      modifiersale,
      name_ar
    } = this.state;
    let id = this.props.match.params.id;
    const data: any = new FormData();
    if (this.state.images) {
      for (var x = 0; x < this.state.images.length; x++) {
        data.append("files", this.state.images[x]);
      }
    }
    data.append("modifier_name", modifiername);
    data.append("modifier_type", modifiertype);
    data.append("erp_id", erp_id);
    data.append("modifier_cost_price", modifiercost);
    data.append("modifier_sale_price", modifiersale);
    data.append("priority", priority);
    data.append("mod_groups_json", JSON.stringify(modGroupJson));
    data.append("instashop_category", instashop_category);
    data.append("brand_id", brand_id);
    data.append("name_ar", name_ar);

    let type = this.props.match.params.type;
    this.props.editModifier(id, data, type, this.handleState);
  };

  handleInstashopCategor = (e: any) => {
    this.setState({ instashop_category: e.value });
  };
  render() {
    let type = this.props.match.params.type;
    if (sessionStorage.token) {
      jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
        if (err) {
          this.props.logoutUser();
        }
      });
    } else {
      return <Redirect to="/" />;
    }
    let msg;
    let messcolor;
    const { modGroupJson } = this.state;
    const { isUpdated, message, modData, modgroups } = this.props;
    if (isUpdated) {
      msg = message;
      messcolor = "green";
    } else {
      msg = message;
      messcolor = "red";
    }
    return (
      <div className="page">
        <CheckChanges path="/edit-modifier" />
        <Topbar />
        <div className="page-content d-flex align-items-stretch">
          <Sidebar />
          <div className="content-inner">
            {/*  Page Header */}
            <header className="page-header py-0">
              <div className="container-fluid">
                <div className="d-flex justify-content-between py-3">
                  <h4 className="mt-2">Modifier Management</h4>
                </div>
              </div>
            </header>
            {/* Breadcrumb */}
            <div className="breadcrumb-holder container-fluid">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/modifiers" className="text-primary">
                    Modifiers
                  </Link>
                </li>
                <li className="breadcrumb-item active">Edit Modifier</li>
              </ul>
            </div>
            <section className="forms">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-body">
                        <strong>
                          <p
                            className="text-center font-weight-light mb-4"
                            style={{ color: messcolor }}
                          >
                            {msg}
                          </p>
                        </strong>
                        <div className="row">
                          <div className="col">
                            <div className="form-group">
                              <label className="form-control-label">
                                Modifier Name{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                disabled={
                                  modData.derived_from_variant ? true : false
                                }
                                id="modifiername"
                                type="text"
                                name="modifiername"
                                defaultValue={modData.modifier_name}
                                required
                                data-msg="Please enter Modifier Name"
                                className="input-material"
                                onChange={this.handleInputChange}
                              />
                            </div>

                            <div className="form-group">
                                  <label className="form-control-label">
                                    Modifier Arabic Name{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <input
                                    id="modifiername"
                                    type="text"
                                    name="name_ar"
                                    defaultValue={modData.name_ar}

                                    required
                                    data-msg="Please enter Modifier Arabic Name"
                                    className="input-material"
                                    onChange={this.handleInputChange}
                                  />
                                </div>
                          </div>

                          <div className="col">
                            <div className="form-group">
                              <label className="form-control-label">
                                Erp Id <span className="text-danger">*</span>
                              </label>
                              <input
                                disabled={
                                  modData.derived_from_variant ? true : false
                                }
                                id="erp_id"
                                type="text"
                                name="erp_id"
                                defaultValue={modData.erp_id}
                                required
                                data-msg="Please enter Modifier Name"
                                className="input-material"
                                onChange={this.handleInputChange}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col">
                            <div className="form-group">
                              <label className="form-control-label">
                                Erp Id <span className="text-danger">*</span>
                              </label>
                              <input
                                disabled
                                id="erp_id"
                                type="text"
                                name="erp_id"
                                defaultValue={modData.brand_name}
                                required
                                data-msg="Please enter Modifier Name"
                                className="input-material"
                                onChange={this.handleInputChange}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-8 col-sm-8 col-12">
                            <div className="form-group">
                              <label className="form-control-label">
                                Modifier Group{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <Select
                                isMulti
                                name="groups"
                                value={modGroupJson}
                                options={modgroups}
                                className="text-capitalize basic-multi-select mt-2"
                                classNamePrefix="select"
                                onChange={(e, i) =>
                                  this.handleModGroupsInputChange(e, i)
                                }
                              />
                            </div>
                          </div>
                          <div className="col-lg-4 col-sm-4 col-12">
                            <div className="form-group">
                              <label className="form-control-label">
                                Priority
                              </label>
                              <input
                                id="priority"
                                type="text"
                                name="priority"
                                defaultValue={modData.priority}
                                required
                                data-msg="Please enter Priority"
                                className="input-material"
                                onChange={this.handleInputChange}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col">
                            <div className="form-group">
                              <label className="form-control-label">
                                Modifier Type
                              </label>
                              <input
                                id="modifiertype"
                                type="text"
                                name="modifiertype"
                                defaultValue={modData.modifier_type}
                                className="input-material"
                                onChange={this.handleInputChange}
                              />
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-group">
                              <label className="form-control-label mb-3">
                                Modifier Image
                              </label>
                              <input
                                id="files"
                                type="file"
                                name="files"
                                className="form-control-file"
                                onChange={this.fileSelectedHandler}
                                multiple
                              />
                              <small className="form-text">
                                You can also choose multiple images.
                              </small>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          {/* <div className="col">
                            <div className="form-group">
                              <label className="form-control-label">
                                Cost Price{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                disabled={
                                  modData.derived_from_variant ? true : false
                                }
                                id="modifiercost"
                                type="number"
                                name="modifiercost"
                                defaultValue={modData.modifier_cost_price}
                                required
                                data-msg="Please enter Cost Price"
                                className="input-material"
                                onChange={this.handleInputChange}
                              />
                            </div>
                          </div> */}
                          <div className="col">
                            <div className="form-group">
                              <label className="form-control-label">
                                Sale Price{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                disabled={
                                  modData.derived_from_variant ? true : false
                                }
                                id="modifiersale"
                                type="number"
                                name="modifiersale"
                                defaultValue={modData.modifier_sale_price}
                                required
                                data-msg="Please enter Sale Price"
                                className="input-material"
                                onChange={this.handleInputChange}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="form-group d-flex justify-content-end mt-4">
                          <button
                            onClick={this.handleSubmit}
                            disabled={!this.isModifierReady()}
                            className="btn btn-primary"
                          >
                            Update Modifier
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <Footer />
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state: any) => {
  return {
    modData: state.menu.modData,
    modgroups: state.menu.modgroups,
    message: state.menu.message,
    isUpdated: state.menu.isUpdated,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    logoutUser: function () {
      dispatch(logoutUser());
    },
    editModifier: function (id: any, data: any, type: any, handleState: any) {
      dispatch(editModifier(id, data, type, handleState));
    },
    getModifier: function (id: number, type: any) {
      //u
      dispatch(getModifier(id, type));
    },
    modGroupsList: function () {
      dispatch(modGroupsList());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(EditMod);
