import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Topbar from "../../components/topbar";
import Sidebar from "../../components/sidebar";
import Footer from "../../components/footer/main";
import jwt from "jsonwebtoken";
import { secretKey } from "../../secret";
import { connect } from "react-redux";
import {
  menusList,
  groupsListByMenuId,
  logoutUser,
  publishMenu,
  publishLater,
  countryList,
  statesList,
  brandsList,
  storesListForMultiSelect,
} from "../../redux";
import { MenuProps } from "../../interfaces/menu";
import {
  AggregatorSync,
  cloneMenu,
  menusListForMaster,
  storesListForMultiSelectInMenu,
  syncMenuAggregator,
} from "../../redux/actions/menuAction";
import Select from "react-select";
import { aggregatorsList } from "../../redux/actions/aggregatorsAction";

class ActionFormatter extends Component<
  { row: any; data: any },
  {
    [x: number]: any;
    activeTab: any;
    date: any;
    storesJson: any;
    time: any;
    menu_name: any;
    aggregator_id: any;
    selectedOption: any;
    isLoading: boolean;
    setIsModalOpen: boolean;
  }
> {
  constructor(readonly props: any) {
    super(props);

    this.state = {
      activeTab: 0,
      date: "",
      time: "",
      menu_name: "",
      aggregator_id: null,
      storesJson: [],
      selectedOption: "specificStores",
      isLoading: false,
      setIsModalOpen: false,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleRadioChange = this.handleRadioChange.bind(this);
  }

  handleRadioChange = (event: any) => {
    const { id: name } = event.target;
    if (name === "allStores") {
      this.setState({
        storesJson: "All",
        selectedOption: "allStores",
      });
    } else if (name === "specificStores") {
      this.setState({
        storesJson: [],
        selectedOption: "specificStores",
      });
    }
  };
  showgroups = (id: any) => {
    this.props.data.groupsListByMenuId(id);
  };
  showStores = (id: any, aggregator_id: any) => {
    this.props.data.storesList(id, aggregator_id);
  };
  handleInputChange(event: { target: { name: any; value: any } }) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }
  ispublishReady = () => {
    const { date } = this.state;
    return date !== "";
  };
  handleUnPublish = (id: any) => {
    let menu_type: any =
      this.props.row.is_master == 1 ? "master" : "aggregator";
    let data = { is_publish: 0, menu_type: menu_type };
    this.props.data.publishMenu(id, data, this.props.row.menu_name);
  };

  handleClone = (master_menu_id: any) => {
    let { menu_name, aggregator_id } = this.state;
    let data = {
      master_menu_id,
      menu_name,
      aggregator_id,
      brand_id: this.props.row.brand_id,
    };
    console.log("data", data);
    this.props.data.cloneMenu(data);
  };

  handleMenuSync = (master_menu_id: any) => {
    let { aggregator_id } = this.state;
    let data = {
      aggregator_id,
      master_menu_id,
    };
    this.props.data.syncMenu(data);
  };
  handleSync = (menu_id: any) => {
    let token: any = sessionStorage.getItem("token");
    let decoded: any = jwt.decode(token);
    let data: any = {
      menu_id,
      stores: this.state.storesJson,
      brand_id: decoded.brand_id,
      role_id: decoded.role_id,
      user_group_id: decoded.user_group_id,
      aggregator_id: this.props.row.aggregator_id,
    };
    this.props.data.AggregatorSync(data);
  };

  hanldeAggregator = (e: any) => {
    this.setState({ aggregator_id: e.value });
  };
  handlePublish = (id: any) => {
    let { activeTab, date, time } = this.state;
    let { row } = this.props;
    if (activeTab === 0) {
      let data = {};
      data = {
        is_publish: 1,
        aggregator_id: row.aggregator_id,
      };
      if (row.is_master == 1) {
        Object.assign(data, { type: "master" });
      }
      this.props.data.publishMenu(id, data, null);
    } else {
      let data = {
        date: new Date(date),
        time: time,
        is_publish: 1,
      };
      this.props.data.publishLater(id, data, row.menu_name);
    }
  };
  handleStoresInputChange = (e: any, index: any) => {
    if (e && e.length > 0) {
      this.setState({ storesJson: e });
    } else {
      this.setState({ storesJson: [] });
    }
  };
  render() {
    const { row, data } = this.props;
    let finalAggregators: any = [];
    data.aggregators.map((info: any) => {
      if (info.is_active == 1) {
        finalAggregators.push({ label: info.aggregator_name, value: info.id });
      }
    });
    const today = new Date().toISOString().split("T")[0];
    return (
      <div>
        <button
          title="View Groups"
          data-toggle="modal"
          data-target={`#viewgroups${row.menu_id}`}
          onClick={() => this.showgroups(row.menu_id)}
          className="btn btn-outline-info mr-2 mb-2"
        >
          <i className="fa fa-list"></i>
        </button>
        <Link
          title="Edit Menu"
          className="btn btn-outline-primary mr-2 mb-2"
          to={`/edit-menu/${row.menu_id}/${row.is_master && row.is_master == 1 ? "master" : "aggregator"}`}
        >
          <i className="fa fa-edit"></i>
        </Link>
        {row.is_master != 1 && (
          <button
            title="Publish Menu"
            data-toggle="modal"
            data-target={`#publish${row.menu_id}`}
            className="btn btn-outline-info mr-2 mb-2"
          >
            <img
              title="Publish Menu"
              alt="publish"
              className="rounded-circle"
              src={process.env.PUBLIC_URL + "/assets/img/publishform.png"}
              width="20px"
              height="20px"
            />
          </button>
        )}
        {row.is_master != 1 && (
          <button
            title="Sync Menu"
            data-toggle="modal"
            data-target={`#sync${row.menu_id}`}
            onClick={() =>
              this.showStores(row.brand_id, this.props.row.aggregator_id)
            }
            className="btn btn-outline-info mr-2 mb-2"
          >
            <img
              title="Sync Menu with Aggregator"
              alt="sync menu"
              className="rounded-circle"
              src={process.env.PUBLIC_URL + "/assets/img/sync.png"}
              width="20px"
              height="20px"
            />
          </button>
        )}
        {row.is_master == 1 && (
          <button
            title="Clone Menu"
            data-toggle="modal"
            data-target={`#clone${row.menu_id}`}
            className="btn btn-outline-info mr-2 mb-2"
          >
            <i className="fa fa-clone"></i>
          </button>
        )}
        {row.is_publish === 1 && !row.cron_job_id && (
          <button
            title="UnPublish Menu"
            data-toggle="modal"
            data-target={`#unpublish${row.menu_id}`}
            className="btn btn-outline-danger mr-2 mb-2"
          >
            <img
              title="UnPublish Menu"
              alt="unpublish"
              className="rounded-circle"
              src={process.env.PUBLIC_URL + "/assets/img/unpublish.png"}
              width="20px"
              height="20px"
            />
          </button>
        )}
        {row.is_master == 1 && (
          <button
            title="Sync Menu with Aggregator"
            data-toggle="modal"
            data-target={`#syncMenu${row.menu_id}`}
            className="btn btn-outline-info mr-2 mb-2"
          >
            <img
              title="Sync Menu with Aggregator"
              alt="sync menu"
              className="rounded-circle"
              src={process.env.PUBLIC_URL + "/assets/img/sync.png"}
              width="20px"
              height="20px"
            />
          </button>
        )}
        {/* <!-- Modal--> */}
        <div
          id={`viewgroups${row.menu_id}`}
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          className="modal fade text-left"
        >
          <div role="document" className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h4 id="exampleModalLabel" className="modal-title">
                  Groups
                </h4>
                <button
                  type="button"
                  data-dismiss="modal"
                  aria-label="Close"
                  className="close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-12">
                    <div className="form-group text-capitalize">
                      {data.groups.length > 0 ? (
                        <BootstrapTable version="4" data={data.groups} hover>
                          <TableHeaderColumn
                            dataField="group_id"
                            columnTitle
                            isKey
                          >
                            #
                          </TableHeaderColumn>
                          <TableHeaderColumn dataField="group_name" columnTitle>
                            Group Name
                          </TableHeaderColumn>
                        </BootstrapTable>
                      ) : (
                        <p className="text-center">Groups not found</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  data-dismiss="modal"
                  className="btn btn-danger"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Publish Modal--> */}
        <div
          id={`publish${row.menu_id}`}
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          className="modal fade text-left"
        >
          <div role="document" className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h4 id="exampleModalLabel" className="modal-title">
                  Publish Menu
                </h4>
                <button
                  type="button"
                  data-dismiss="modal"
                  aria-label="Close"
                  className="close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <Tabs
                  onSelect={(index) =>
                    this.setState({ activeTab: index, date: "", time: "" })
                  }
                >
                  <TabList>
                    <Tab>Publish Now</Tab>
                    <Tab {...(row.cron_job_id && { disabled: true })}>
                      Publish Later
                    </Tab>
                  </TabList>

                  <TabPanel style={{ marginTop: 15 }}>
                    <p>Are you sure,you want to Publish this menu?</p>
                  </TabPanel>
                  <TabPanel style={{ marginTop: 15 }}>
                    <div className="row">
                      <div className="col">
                        <div className="form-group">
                          <label>Date</label>
                          <input
                            type="datetime-local"
                            name="date"
                            min={today}
                            required
                            data-msg="Please enter Date"
                            placeholder="DD/MM/YYYY"
                            className="form-control"
                            onChange={this.handleInputChange}
                          />
                        </div>
                      </div>
                      {/* <div className="col">
                                                <div className="form-group">
                                                    <label>Time</label>
                                                    <input type="time" name="time" required data-msg="Please enter Time" className="form-control" onChange={this.handleInputChange} />
                                                </div>
                                            </div> */}
                    </div>
                  </TabPanel>
                </Tabs>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  data-dismiss="modal"
                  className="btn btn-secondary"
                >
                  Close
                </button>
                <button
                  {...(this.state.activeTab == 1 && {
                    disabled: !this.ispublishReady(),
                  })}
                  onClick={(e) => {
                    e.preventDefault();
                    this.handlePublish(row.menu_id);
                  }}
                  className="btn btn-primary"
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- UnPublish Modal--> */}
        {/* Sync With Aggregator */}
        <div
          id={`sync${row.menu_id}`}
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          className="modal fade text-left"
        >
          <div role="document" className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h4 id="exampleModalLabel" className="modal-title">
                  Sync Menu with Aggregator
                </h4>
                <button
                  type="button"
                  data-dismiss="modal"
                  aria-label="Close"
                  className="close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <p>Are you sure,you want to Sync with Aggregator ?</p>

                <div className="d-flex">
                  <div className="form-check col-6">
                    <input
                      className="form-check-input radio-template"
                      type="radio"
                      name="stores"
                      id="specificStores"
                      checked={this.state.selectedOption === "specificStores"}
                      onChange={this.handleRadioChange}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="specificStores"
                    >
                      {" "}
                      Specific Stores{" "}
                    </label>
                  </div>
                  <div className="form-check col-6">
                    <input
                      className="form-check-input radio-template"
                      type="radio"
                      name="stores"
                      id="allStores"
                      checked={this.state.selectedOption === "allStores"}
                      onChange={this.handleRadioChange}
                    />
                    <label className="form-check-label" htmlFor="allStores">
                      {" "}
                      All Stores{" "}
                    </label>
                  </div>
                </div>

                {this.state.selectedOption === "specificStores" ? (
                  <div className="form-group mt-3">
                    <label className="form-control-label">
                      Select Store <span className="text-danger">*</span>
                    </label>
                    <Select
                      isMulti
                      name="stores"
                      required
                      options={this.props.data.stores}
                      className="text-capitalize basic-multi-select mt-2"
                      classNamePrefix="select"
                      onChange={(e, i) => this.handleStoresInputChange(e, i)}
                      closeMenuOnSelect={false}
                    />
                  </div>
                ) : null}
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  data-dismiss="modal"
                  className="btn btn-secondary"
                >
                  Close
                </button>
                <button
                  data-dismiss="modal"
                  {...(this.state.activeTab == 1 && {
                    disabled: !this.ispublishReady(),
                  })}
                  onClick={() => {
                    this.handleSync(row.menu_id);
                  }}
                  className="btn btn-primary"
                  {...(this.state.selectedOption === "specificStores" &&
                    this.state.storesJson.length == 0 && { disabled: true })}
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
        {/*  */}
        <div
          id={`unpublish${row.menu_id}`}
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          className="modal fade text-left"
        >
          <div role="document" className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h4 id="exampleModalLabel" className="modal-title">
                  UnPublish Menu
                </h4>
                <button
                  type="button"
                  data-dismiss="modal"
                  aria-label="Close"
                  className="close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <p>Are you sure,you want to UnPublish this menu?</p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  data-dismiss="modal"
                  className="btn btn-secondary"
                >
                  Close
                </button>
                <button
                  onClick={() => this.handleUnPublish(row.menu_id)}
                  className="btn btn-primary"
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          id={`syncMenu${row.menu_id}`}
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          className="modal fade text-left"
        >
          <div role="document" className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h4 id="exampleModalLabel" className="modal-title">
                  Sync Menu with Aggregator
                </h4>
                <button
                  type="button"
                  data-dismiss="modal"
                  aria-label="Close"
                  className="close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="container">
                  <div className="row">
                    <div className="col">
                      <div className="form-group">
                        <label className="form-control-label">
                          Aggregator <span className="text-danger">*</span>
                        </label>
                        <Select
                          options={finalAggregators}
                          onChange={this.hanldeAggregator}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  data-dismiss="modal"
                  disabled={this.state.isLoading}
                  className="btn btn-secondary"
                >
                  Close
                </button>
                <button
                  onClick={() => this.handleMenuSync(row.menu_id)}
                  className="btn btn-primary"
                >
                  {this.state.isLoading ? "Loading..." : "Submit"}
                </button>
              </div>
            </div>
          </div> 
        </div> 

        {/* Clone Menu */}
        <div
          id={`clone${row.menu_id}`}
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          className="modal fade text-left"
        >
          <div role="document" className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h4 id="exampleModalLabel" className="modal-title">
                  Clone Menu
                </h4>
                <button
                  type="button"
                  data-dismiss="modal"
                  aria-label="Close"
                  className="close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="container">
                  <div className="row">
                    <div className="col">
                      <div className="form-group">
                        <label className="form-control-label">
                          Menu name <span className="text-danger">*</span>
                        </label>
                        <input
                          id="menuname"
                          type="text"
                          name="menu_name"
                          required
                          data-msg="Please enter Menu Name"
                          className="input-material"
                          onChange={this.handleInputChange}
                        />
                      </div>

                      <div className="form-group">
                        <label className="form-control-label">
                          Aggregator <span className="text-danger">*</span>
                        </label>
                        <Select
                          options={finalAggregators}
                          onChange={this.hanldeAggregator}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  data-dismiss="modal"
                  disabled={this.state.isLoading}
                  className="btn btn-secondary"
                >
                  Close
                </button>
                <button
                  onClick={() => this.handleClone(row.menu_id)}
                  className="btn btn-primary"
                >
                  {this.state.isLoading ? "Loading..." : "Submit"}
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* Clone Menu */}
      </div>
    );
  }
}

function actionFormatter(cell: any, row: any, props: any, state: any) {
  return <ActionFormatter row={row} data={props} />;
}

class Menu extends Component<MenuProps, { menu_type: any; brand_id: any }> {
  constructor(readonly props: any) {
    super(props);
    this.state = {
      menu_type: "",
      brand_id: null,
    };
  }
  componentDidMount() {
    this.props.brandsList();
    let token: any = sessionStorage.getItem("token");
    let decoded: any = jwt.decode(token);
    let role_id: any = decoded.role_id;
    if (role_id != 7) {
      this.setState({ brand_id: decoded.brand_id });
    }
    // this.props.menusList();
    this.props.aggregatorsList();
    document.title = "SimpleXSync | Menus";
  }

  handleMenuType = (e: any) => {
    this.setState({ menu_type: e.target.value });
    if (this.state.brand_id) {
      this.props.menusList(e.target.value, this.state.brand_id);
    }
  };

  handleSubmit = () => {
    this.props.menusList(this.state.menu_type);
  };

  handleBrandsInputChange = (e: any, i: any) => {
    this.setState({ brand_id: e.value });
    this.props.menusList(this.state.menu_type, e.value);
  };

  render() {
    if (sessionStorage.token) {
      jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
        if (err) {
          this.props.logoutUser();
        }
      });
    } else {
      return <Redirect to="/" />;
    }
    let token: any = sessionStorage.getItem("token");
    let decoded: any = jwt.decode(token);
    let role_id: any = decoded.role_id;
    let selectedBrands: any = [];
    if (decoded.role_id == 7) {
      selectedBrands = this.props.brands;
    } else {
      selectedBrands = this.props.brands.filter((data: any) => {
        return data.brand_id == decoded.brand_id;
      });
    }
    const options: any = {
      // sizePerPageList: [5,10,15],
      sizePerPage: 10, // which size per page you want to locate as default
      page: 1,
      pageStartIndex: 1, // where to start counting the pages
      paginationSize: 3, // the pagination bar size.
      hideSizePerPage: true, //You can hide the dropdown for sizePerPage
      insertModal: () => {
        return <Redirect to="/add-menu" />;
      },
      noDataText: "Menus Not Found",
    };
    return (
      <div className="page">
        <Topbar />
        <div className="page-content d-flex align-items-stretch">
          <Sidebar />
          <div className="content-inner">
            {/* <!-- Page Header--> */}
            <header className="page-header py-0">
              <div className="container-fluid">
                <div className="d-flex justify-content-between py-3">
                  <h4 className="mt-2">Menus Management</h4>
                </div>
              </div>
            </header>
            <section className="tables">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-1">
                    <label className="form-control-label">
                      Menu Type <span className="text-danger">*</span>
                    </label>
                  </div>
                  <div className="col-11">
                    <div className="form-group">
                      <select
                        name="menu_type"
                        className="form-control text-capitalize mt-2"
                        required
                        data-msg="Please select menu type"
                        onChange={this.handleMenuType}
                      >
                        <option value="">Select Menu</option>
                        <option value="master">Master Menu</option>
                        {/* <option value="aggregator">Aggregator Menu</option> */}
                        {this.props.aggregators.map((data: any) => {
                          return (
                            <option value={data.id}>
                              {data.aggregator_name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  {/* <div className="col-3">
                                        <br />
                                        <button onClick={this.handleSubmit} className="btn btn-primary mt-3">Submit</button>
                                    </div> */}
                </div>
                {role_id == 7 && (
                  <div className="row">
                    <div className="col-1">
                      <label className="form-control-label">
                        Select Brand <span className="text-danger">*</span>
                      </label>
                    </div>
                    <div className="col-11">
                      <div className="form-group">
                        <Select
                          name="brands"
                          options={selectedBrands}
                          className="text-capitalize basic-multi-select mt-2"
                          classNamePrefix="select"
                          // value={{label:this.state.brand_name,value:this.state.brand_id}}
                          onChange={(e, i) =>
                            this.handleBrandsInputChange(e, i)
                          }
                        />
                      </div>
                    </div>
                  </div>
                )}
                <div className="row">
                  <div className="col">
                    <div className="card">
                      <div className="card-body">
                        <BootstrapTable
                          version="4"
                          data={this.props.data}
                          search={true}
                          pagination={this.props.data.length > 10 && true}
                          options={options}
                          exportCSV={true}
                          insertRow={this.props.data.length == 0 ? true : false}
                          csvFileName="menus.csv"
                          hover
                        >
                          <TableHeaderColumn
                            thStyle={{ fontSize: 15, whiteSpace: "normal" }}
                            tdStyle={{ fontSize: 15, whiteSpace: "normal" }}
                            dataField="menu_id"
                            csvHeader="#"
                            width="100"
                            dataSort={true}
                            isKey
                          >
                            #
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            thStyle={{ fontSize: 15, whiteSpace: "normal" }}
                            tdStyle={{ fontSize: 15, whiteSpace: "normal" }}
                            dataField="menu_name"
                            csvHeader="Menu Name"
                            width="100"
                            columnTitle
                          >
                            Menu name
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            thStyle={{ fontSize: 15, whiteSpace: "normal" }}
                            tdStyle={{ fontSize: 15, whiteSpace: "normal" }}
                            dataField="brand_name"
                            csvHeader="Brand Name"
                            width="100"
                            columnTitle
                          >
                            Brand name
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            thStyle={{ fontSize: 15, whiteSpace: "normal" }}
                            tdStyle={{ fontSize: 15, whiteSpace: "normal" }}
                            dataField="erp_id"
                            csvHeader="Erp Id"
                            width="100"
                            columnTitle
                          >
                            Erp Id
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            thStyle={{ fontSize: 15, whiteSpace: "normal" }}
                            tdStyle={{ fontSize: 15, whiteSpace: "normal" }}
                            dataField="store_type_name"
                            csvHeader="Store Type"
                            width="100"
                            columnTitle
                          >
                            Store type
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            thStyle={{ fontSize: 15, whiteSpace: "normal" }}
                            tdStyle={{ fontSize: 15, whiteSpace: "normal" }}
                            dataField="is_publish"
                            width="100"
                            dataFormat={statusFormatter}
                            export={false}
                          >
                            Status
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            thStyle={{ fontSize: 15, whiteSpace: "normal" }}
                            tdStyle={{ fontSize: 15, whiteSpace: "normal" }}
                            dataField="stores"
                            width="100"
                            dataFormat={storesFormatter}
                            formatExtraData={this.props}
                            export={false}
                          >
                            Stores
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            thStyle={{ fontSize: 15, whiteSpace: "normal" }}
                            tdStyle={{ fontSize: 15, whiteSpace: "normal" }}
                            dataField="action"
                            width="230"
                            dataFormat={actionFormatter}
                            formatExtraData={this.props}
                            export={false}
                          >
                            Action
                          </TableHeaderColumn>
                        </BootstrapTable>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <Footer />
          </div>
        </div>
      </div>
    );
  }
}
function statusFormatter(cell: any) {
  return (
    <div>
      <span
        {...((cell == 1 && { className: "badge badge-success p-2" }) ||
          (cell == 0 && { className: "badge badge-danger p-2" }))}
      >
        {cell == 1 ? "published" : "Un published"}
      </span>
    </div>
  );
}

function storesFormatter(cell: any, row: any, props: any) {
  let stores = row.stores;
  return (
    <div>
      {stores && stores.length > 0 ? (
        <button
          title="View Stores"
          data-toggle="modal"
          data-target={`#viewStores${row.menu_id}`}
          className="btn btn-outline-info"
        >
          <i className="fa fa-list"></i>
        </button>
      ) : null}

      {/* <!-- Modal--> */}
      <div
        id={`viewStores${row.menu_id}`}
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        className="modal fade text-left"
      >
        <div role="document" className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 id="exampleModalLabel" className="modal-title">
                Stores
              </h4>
              <button
                type="button"
                data-dismiss="modal"
                aria-label="Close"
                className="close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-12">
                  <div className="form-group text-capitalize">
                    {stores && stores.length > 0 ? (
                      <BootstrapTable version="4" data={stores} hover>
                        <TableHeaderColumn dataField="value" columnTitle isKey>
                          #
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="label" columnTitle>
                          Store Name
                        </TableHeaderColumn>
                      </BootstrapTable>
                    ) : (
                      <p className="text-center">Stores not found</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                data-dismiss="modal"
                className="btn btn-danger"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state: any) => {
  return {
    data: state.menu.menus,
    groups: state.menu.groups,
    stores: state.menu.storesoptions,
    aggregators: state.aggregator.aggregators,
    brands: state.brand.brands,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    logoutUser: function () {
      dispatch(logoutUser());
    },
    menusList: function (type: any, brand_id: any) {
      dispatch(menusListForMaster(type, brand_id));
    },
    groupsListByMenuId: function (id: any) {
      dispatch(groupsListByMenuId(id));
    },
    publishMenu: function (id: any, data: any, menu_name: any) {
      dispatch(publishMenu(id, data, menu_name));
    },
    publishLater: function (id: any, data: any, menu_name: any) {
      dispatch(publishLater(id, data, menu_name));
    },
    aggregatorsList: () => {
      dispatch(aggregatorsList());
    },
    cloneMenu: (data: any) => {
      dispatch(cloneMenu(data));
    },
    AggregatorSync: (data: any) => {
      dispatch(AggregatorSync(data));
    },
    brandsList: function () {
      dispatch(brandsList());
    },
    storesList: (brand_id: any, aggregator_id: any) => {
      dispatch(storesListForMultiSelectInMenu(brand_id, aggregator_id));
    },
    syncMenu: (data: any) => {
      dispatch(syncMenuAggregator(data));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Menu);
